
/*
 * Base structure
 */

 @import url(font-awesome.min.css);

:root {
  --input-padding-x: .75rem;
  --input-padding-y: .75rem;
}

html,
body {
  height: 100%;
}

body {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  align-items: flex-start;
  -webkit-box-pack: start;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
  font-size: 20px;
}

.container {
  /*width: 42em;*/
  width:auto;
  width: 100%;
  max-width: 700px;
  /*padding: 15px;*/
  margin: auto;
  margin-top:0em;
}

.container ul {
  text-align:left;
}


.alert-info {
  background-color: rgba(209, 236, 241, 1);
}


/*
 * Header
 */
.masthead {
  /*margin-bottom: 4rem;*/
}

.masthead-brand {
  margin-bottom: 2rem;
  margin-top: 1rem;
}

.nav-masthead .nav-link {
  padding: 0.25rem 0;
  font-weight: 700;
  color: rgba(0, 0, 0, .5);
  background-color: transparent;
  border-bottom: .25rem solid transparent;
}

.nav-masthead .nav-link:hover,
.nav-masthead .nav-link:focus {
  border-bottom-color: rgba(0, 0, 0, .75);
}

.nav-masthead .nav-link + .nav-link {
  margin-left: 1rem;
}

.nav-masthead .active {
  color: #000;
  border-bottom-color: #000;
}

/*@media (min-width: 48em) {
  .masthead-brand {
    float: left;
  }
  .nav-masthead {
    float: right;
  }
}*/

.text-justify {
  text-align: justify;
}


/* Logo */
.logo {
  border-radius: 50%;
  margin-top:20px;
  margin-bottom:20px;
}


/*
 * Progress Bar
 */

.progress{
  width: 370px;
  margin: auto;
  margin-top: -10px;
  margin-bottom: 15px;
}


/*
 * Progress Bar
 */

 .chart-container {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
 }


 .questionHanzi {
  font-size: 90%;
  margin:0;
 }




/*
 * Form
 */

.btn-doyoucontinue {
  padding: 5px 10px 5px 10px;
  margin: 0px 10px 5px 10px;
  font-size: 120%;
}


 .btn-answer {
  padding: 8px 4px 8px 4px;
  margin: 6px;
  margin-top: 0px;
  margin-bottom: 14px;
  font-size: 120%;
  width: 100%;
  max-width: 100px;
 }

.btn-nextquestion {
  font-size: 120%;
 }

 .btn-startthetest {
  font-size: 120%;
 }

.question {
  margin-bottom: 10px;
}

.form-answers {
  width: 100%;
  max-width: 420px;
  padding: 15px;
  margin: auto;
  margin-top:0em;
}

.form-signin {
  width: 100%;
  max-width: 420px;
  /*padding: 15px;*/
  margin: auto;
  margin-top:0em;
}

.form-label-group {
  position: relative;
  margin-bottom: 1rem;
}

.form-label-group > input,
.form-label-group > label {
  height: 3.125rem;
  padding: .75rem;
}

.form-label-group > label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0; /* Override default `<label>` margin */
  line-height: 1.5;
  color: #495057;
  pointer-events: none;
  cursor: text; /* Match the input under the label */
  border: 1px solid transparent;
  border-radius: .25rem;
  transition: all .1s ease-in-out;
}

.form-label-group input::-webkit-input-placeholder {
  color: transparent;
}

.form-label-group input:-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-moz-placeholder {
  color: transparent;
}

.form-label-group input::placeholder {
  color: transparent;
}

.form-label-group input:not(:placeholder-shown) {
  padding-top: 1.25rem;
  padding-bottom: .25rem;
}

.form-label-group input:not(:placeholder-shown) ~ label {
  padding-top: .25rem;
  padding-bottom: .25rem;
  font-size: 12px;
  color: #777;
}

.gradient-color {

        color: #e61542;
        background-image: -webkit-linear-gradient(45deg, #e61542 0%, #0d6efd 89%);
        background-clip: text;
        -webkit-background-clip: text;
        text-fill-color: transparent;
        -webkit-text-fill-color: transparent;
      
}

/* Fallback for Edge
-------------------------------------------------- */
@supports (-ms-ime-align: auto) {
  .form-label-group > label {
    display: none;
  }
  .form-label-group input::-ms-input-placeholder {
    color: #777;
  }
}

/* Fallback for IE
-------------------------------------------------- */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .form-label-group > label {
    display: none;
  }
  .form-label-group input:-ms-input-placeholder {
    color: #777;
  }
}





/*
 * Colors
 */








/*
 * Footer
 */

 footer {
  margin-top: 2rem;
 }


.icon {
  text-decoration: none;
  border-bottom: none;
  position: relative;
}

  .icon:before {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-transform: none !important;
  }

  .icon > .label {
    display: none;
  }

  ul.icons {
  cursor: default;
  list-style: none;
  padding-left: 0;
}

  ul.icons li {
    display: inline-block;
    padding: 0 1em 0 0;
  }

    ul.icons li:last-child {
      padding-right: 0;
    }

    ul.icons li .icon:before {
      font-size: 2.5em;
    }

    ul.icons li a {
      color: #404040;
      text-decoration: none !important;
    }

    ul.icons li a {
      transition: color 200ms ease-out;
    }

    ul.icons li a:hover {
      color: #0058B7;
    }
    

